/** @format */

import React from "react";
import PropTypes from "prop-types";
import { map, remove } from "lodash";
import Modal from "../../../../components/Modal";

import baseHelper from "../../../../helpers/BaseHelper";
import { useTranslation } from "react-i18next";
import Input from "../../../../components/Input";
import { useState, useEffect } from "react";
import appConfig from "../../../../config/app";
import { includes, compact, find } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import settingActions from "../../../../redux/futures/setting/actions";
import * as settingTypes from "../../../../redux/futures/setting/types";
import produce from "immer";
import * as paymentTypes from "../../../../redux/futures/payment/types";

ModalAppotaPay.propTypes = {
  onCancel: PropTypes.func,
  paymentGatewayConfigured: PropTypes.any,
};

export default function ModalAppotaPay(props) {
  const { t } = useTranslation();

  const {
    paymentGatewayConfigured,
    onCancel,
    upDateDataCofigPayment,
    connect,
    handleUpdateActivePaymentAPPOTAPay,
    onchangeDataUpdateAPPOTAPay,
    handleUpdateActivePaymentNoteAPPOTAPay,
    appotaPayRef,
    appotaPayAccount,
    onChangeAPPOTAPayInput,
    handleCheckActivePaymentAPPOTAPay,
    handleChangeAccountNameAPPOTAPay,
    handleUpdateAccountNameAPPOTAPay,
    handleUpdateMethodNameAPPOTAPay,
    setAPPOTAPayMode,
    appotaPayMode,
    dataUpdateAPPOTAPay,
    setIsShowAPPOTAPaySetting,
    isShowAPPOTAPaySetting,
    isSubmitLoading,
    logo,
    handleCheckActivePaymentNoteAPPOTAPay,
  } = props;

  return (
    <Modal
      id="modal-appota-pay"
      title={!paymentGatewayConfigured ? t("PAYMENTS.SET_UP_PAYMENT_METHOD") : t("PAYMENTS.DETAIL_PAYMENT_METHOD")}
      onCancel={onCancel}
      hasFooter={true}
      logo={logo}
      onOk={
        !paymentGatewayConfigured
          ? () => connect(appConfig.PAYMENT_PARTNERS.APPOTA.CODE)
          : () => upDateDataCofigPayment(appConfig.PAYMENT_PARTNERS.APPOTA.CODE, paymentGatewayConfigured)
      }
      visible={true}
      width={1000}
      bodyStyles={{ minHeight: "40vh" }}
    >
      <div className="content">
        {paymentGatewayConfigured ? (
          <React.Fragment>
            {
              <React.Fragment>
                <div className="partner-account">
                  <div className="ladiui form-group">
                    <label className="ladiui-label">{t("PAYMENTS.LB_METHOD_NAME")}</label>
                    <Input 
                   ref={(ref) => appotaPayRef.add(ref)}
                   value={dataUpdateAPPOTAPay.method_name}
                   validationName={t('PAYMENTS.LB_METHOD_NAME')}
                   name='method_name'
                   onChange={(event) => onchangeDataUpdateAPPOTAPay(event)}
                   validations={{ isRequired: true }}
                   />
                    
                  </div>
                  <div className="ladiui form-group">
                  <label className="ladiui-label">{t("PAYMENTS.LB_METHOD_DESCRIPTION")}</label>
                      <Input 
                        ref={(ref) => appotaPayRef.add(ref)}
                        value={dataUpdateAPPOTAPay.description}
                        validationName={t('PAYMENTS.LB_METHOD_DESCRIPTION')}
                        name='description'
                        onChange={(e) => onchangeDataUpdateAPPOTAPay(e)}
                        validations={{ isRequired: true }}
                       />
                    </div>

                  <div className="ladiui form-group">
                    <label className="ladiui-label">{t("PAYMENTS.APPOTA_PAY.PARTNER_CODE")}</label>
                    <Input value={paymentGatewayConfigured.config.partner_code} disabled={true} />
                  </div>

                  <div className="ladiui form-group">
                    <label className="ladiui-label">{t("PAYMENTS.APPOTA_PAY.API_KEY")}</label>
                    <Input value={paymentGatewayConfigured.config.api_key} disabled={true} />
                  </div>

                  <div className="ladiui form-group">
                    <label className="ladiui-label">{t("PAYMENTS.APPOTA_PAY.SECRET_KEY")}</label>
                    <Input value={paymentGatewayConfigured.config.secret_key} disabled={true} />
                  </div>

                  <div className="ladiui form-group">
                    <label className="ladiui-label">{t("PAYMENTS.APPOTA_PAY.MERCHANT_EXPIRED_TIME")}</label>
                    <div>
                      <Input
                        ref={(ref) => appotaPayRef.add(ref)}
                        name="expired_time"
                        type="number"
                        value={dataUpdateAPPOTAPay.expired_time}
                        validationName={"ngày hết hạn"}
                        onChange={(event) => onchangeDataUpdateAPPOTAPay(event)}
                        validations={{ isRequired: true }}
                        style={{ width: "15%", display: "inline-block", marginRight: "20px" }}
                      />
                    </div>
                  </div>

                  <div className="ladiui form-group">
                    <label className="ladiui-label payment-method-title">{t("PAYMENTS.LB_SELECT_PAYMENT")}</label>
                    <div className="ladiui-checkout-payment-body">
                      {map(appConfig.PAYMENT_METHODS.APPOTA.TYPE, (paymentMethod) => {
                        return (
                          <div key={paymentMethod.CODE} className="ladiui-checkout-payment-item">
                            <div className="ladiui-checkout-payment-item-header ladiui-checkout-payment-header-show-2">
                              <span>
                                <label className="flex">
                                  <input
                                    type="checkbox"
                                    className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                                    value={paymentMethod.CODE}
                                    onClick={(e) => handleUpdateActivePaymentAPPOTAPay(e)}
                                    defaultChecked={includes(paymentGatewayConfigured.config.active_payment_methods, paymentMethod.CODE)}
                                  />
                                  <span>{paymentMethod.NAME}</span>
                                </label>
                              </span>
                              {paymentMethod.CODE == "VA" && dataUpdateAPPOTAPay.active_payment_methods.includes(paymentMethod.CODE) && (
                                <input
                                  type="text"
                                  className="ladiui form-control mt-8 payment-method-note ml-24"
                                  name={"account_name"}
                                  placeholder="Người nhận chuyển khoản (tên không dấu, không chứa số và ký tự đặc biệt)"
                                  value={dataUpdateAPPOTAPay.account_name}
                                  // defaultValue={
                                  //   paymentGatewayConfigured.config.account_name ? paymentGatewayConfigured.config.account_name : ""
                                  // }
                                  onChange={(event) => handleUpdateAccountNameAPPOTAPay(event)}
                                />
                              )}
                              {dataUpdateAPPOTAPay.active_payment_methods.includes(paymentMethod.CODE) && (
                                <input
                                  type="text"
                                  className="ladiui form-control mt-8 payment-method-note ml-24"
                                  name={paymentMethod.CODE}
                                  placeholder="Ghi chú"
                                  defaultValue={
                                    paymentGatewayConfigured.config.active_payment_methods_note
                                      ? paymentGatewayConfigured.config.active_payment_methods_note[paymentMethod.CODE]
                                      : ""
                                  }
                                  onChange={(event) => handleUpdateActivePaymentNoteAPPOTAPay(event)}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div id="data-tooltip-guide" className="ladiui form-group">
                    <label className="ladiui-label">
                      <span className="tooltip-paymet-guide">{t("PAYMENTS.LB_PAYMENT_GUIDE")}</span>
                      <span className="tooltip-icon" data-tooltip={t("PAYMENTS.LB_TT_PAYMENT_GUIDE")} data-tooltip-position="right">
                        {" "}
                        <i className="ladi-icon icon-c-question" />
                      </span>
                    </label>
                    <textarea
                      name="payment_guide"
                      className="ladiui textarea"
                      defaultValue={paymentGatewayConfigured.config.payment_guide}
                      onChange={(event) => onchangeDataUpdateAPPOTAPay(event)}
                    />
                  </div>
                  {baseHelper.isDevelopEnv() && (
                    <div className="ladiui form-group flex">
                      <input
                        type="checkbox"
                        className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                        checked={paymentGatewayConfigured.mode === appConfig.MODE.TEST}
                        disabled={true}
                      />
                      <label htmlFor="ale_pay_mode"> {t("MODE.TEST")}</label>
                    </div>
                  )}
                </div>
              </React.Fragment>
            }
          </React.Fragment>
        ) : (
          <React.Fragment>
            {
              <div>
                <div className="partner-account">
                  <div className="ladiui form-group">
                    <label className="ladiui-label">{t("PAYMENTS.LB_METHOD_NAME")}</label>
                    <Input
                      ref={(ref) => appotaPayRef.add(ref)}
                      value={appotaPayAccount.method_name}
                      validationName={t("PAYMENTS.LB_METHOD_NAME")}
                      name="method_name"
                      onChange={(event) => onChangeAPPOTAPayInput(event)}
                      validations={{ isRequired: true }}
                    />
                  </div>

                  <div className="ladiui form-group">
                    <label className="ladiui-label">{t("PAYMENTS.APPOTA_PAY.PARTNER_CODE")}</label>
                    <Input
                      ref={(ref) => appotaPayRef.add(ref)}
                      value={appotaPayAccount.partner_code}
                      validationName={t("PAYMENTS.APPOTA_PAY.PARTNER_CODE")}
                      name="partner_code"
                      placeholder="Nhập vào Partner Code"
                      onChange={(event) => onChangeAPPOTAPayInput(event)}
                      validations={{ isRequired: true }}
                    />
                  </div>

                  <div className="ladiui form-group">
                    <label className="ladiui-label">{t("PAYMENTS.APPOTA_PAY.API_KEY")}</label>
                    <Input
                      ref={(ref) => appotaPayRef.add(ref)}
                      name="api_key"
                      value={appotaPayAccount.api_key}
                      placeholder="Nhập vào Api Key"
                      validationName={t("PAYMENTS.APPOTA_PAY.API_KEY")}
                      onChange={(event) => onChangeAPPOTAPayInput(event)}
                      validations={{ isRequired: true }}
                    />
                  </div>

                  <div className="ladiui form-group">
                    <label className="ladiui-label">{t("PAYMENTS.APPOTA_PAY.SECRET_KEY")}</label>
                    <Input
                      ref={(ref) => appotaPayRef.add(ref)}
                      name="secret_key"
                      value={appotaPayAccount.secret_key}
                      placeholder="Nhập vào Secret Key"
                      validationName={t("PAYMENTS.APPOTA_PAY.SECRET_KEY")}
                      onChange={(event) => onChangeAPPOTAPayInput(event)}
                      validations={{ isRequired: true }}
                    />
                  </div>

                  <div className="ladiui form-group">
                    <label className="ladiui-label">{t("PAYMENTS.APPOTA_PAY.MERCHANT_EXPIRED_TIME")}</label>
                    <div>
                      <Input
                        ref={(ref) => appotaPayRef.add(ref)}
                        name="expired_time"
                        type="number"
                        value={appotaPayAccount.expired_time}
                        validationName={"ngày hết hạn"}
                        onChange={(event) => onChangeAPPOTAPayInput(event)}
                        validations={{ isRequired: true }}
                        style={{ width: "15%" }}
                      />
                    </div>
                  </div>

                  <div className="ladiui form-group">
                    <label className="ladiui-label payment-method-title">{t("PAYMENTS.LB_SELECT_PAYMENT")}</label>
                    <div className="ladiui-checkout-payment-body">
                      {map(appConfig.PAYMENT_METHODS.APPOTA.TYPE, (paymentMethod) => {
                        return (
                          <div key={paymentMethod.CODE} className="ladiui-checkout-payment-item">
                            <div className="ladiui-checkout-payment-item-header ladiui-checkout-payment-header-show-2">
                              <span>
                                <label className="flex">
                                  <input
                                    type="checkbox"
                                    value={paymentMethod.CODE}
                                    onClick={(e) => handleCheckActivePaymentAPPOTAPay(e)}
                                    className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                                    defaultChecked={includes(appotaPayAccount.active_payment_methods, paymentMethod.CODE)}
                                  />
                                  <span>{paymentMethod.NAME}</span>
                                </label>
                              </span>
                              {paymentMethod.CODE == "VA" && appotaPayAccount.active_payment_methods.includes(paymentMethod.CODE) && (
                                <input
                                  type="text"
                                  className="ladiui form-control mt-8 payment-method-note ml-24"
                                  name={"account_name"}
                                  placeholder="Người nhận chuyển khoản (tên không dấu, không chứa số và ký tự đặc biệt)"
                                  value={appotaPayAccount.account_name}
                                  onChange={(event) => handleChangeAccountNameAPPOTAPay(event)}
                                />
                              )}
                              {appotaPayAccount.active_payment_methods.includes(paymentMethod.CODE) && (
                                <input
                                  type="text"
                                  className="ladiui form-control mt-8 payment-method-note ml-24"
                                  name={paymentMethod.CODE}
                                  placeholder="Ghi chú"
                                  defaultValue=""
                                  onChange={(event) => handleCheckActivePaymentNoteAPPOTAPay(event)}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div id="data-tooltip-guide" className="ladiui form-group">
                    <label className="ladiui-label">
                      <span className="tooltip-paymet-guide">{t("PAYMENTS.LB_PAYMENT_GUIDE")}</span>
                      <span className="tooltip-icon" data-tooltip={t("PAYMENTS.LB_TT_PAYMENT_GUIDE")} data-tooltip-position="right">
                        {" "}
                        <i className="ladi-icon icon-c-question" />
                      </span>
                    </label>
                    <textarea
                      name="payment_guide"
                      className="ladiui textarea"
                      value={appotaPayAccount.payment_guide}
                      onChange={(event) => onChangeAPPOTAPayInput(event)}
                    />
                  </div>
                  {baseHelper.isDevelopEnv() && (
                    <div className="ladiui form-group flex">
                      <input
                        id="vnpt_e_pay_mode"
                        type="checkbox"
                        className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                        onChange={() => setAPPOTAPayMode(!appotaPayMode)}
                        checked={appotaPayMode == appConfig.MODE.TEST}
                      />
                      <label htmlFor="vnpt_e_pay_mode"> {t("MODE.TEST")}</label>
                    </div>
                  )}
                </div>
              </div>
            }
          </React.Fragment>
        )}
        {/* <div className="partner-footer">
                    <div className="right" style={{ display: "flex" }}>
                        <button className="ladiui btn btn-secondary"
                            onClick={onCancel}
                        >{t('ACTIONS.CANCEL')}</button>
                        {!paymentGatewayConfigured ?
                            <button className={`ml-16 ladiui btn btn-primary`} onClick={() => connect(appConfig.PAYMENT_PARTNERS.VNPT_EPAY.CODE)}> {t('ACTIONS.ENABLE')}</button>
                            :
                            <button onClick={() => upDateDataCofigPayment(paymentGatewayConfigured)} className="ml-16 ladiui btn btn-primary btn-sm">{t('COMMON.SAVE')}</button>
                        }
                    </div>
                </div> */}
      </div>
    </Modal>
  );
}
