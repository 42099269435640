/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';

import baseHelper from '../../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import appConfig from '../../../../config/app';
import { map, includes } from 'lodash';

ModalAlePay.propTypes = {
    onCancel: PropTypes.func,
    paymentGatewayConfigured: PropTypes.any,
};

export default function ModalAlePay(props) {
    const {
        paymentGatewayConfigured,
        onCancel,
        upDateDataCofigPayment,
        connect,
        handleUpdateActivePaymentALEPay,
        onchangeDataUpdateAlePay,
        alePayRef,
        alePayAccount,
        onChangeAlePayInput,
        handleCheckActivePaymentALEPay,
        setAlePayMode,
        alePayMode,
        setIsShowAlePaySetting,
        isShowAlePaySetting,
        isSubmitLoading,
        logo,
        dataUpdateAlePay,
        handleUpdateActivePaymentNoteALEPay,
        handleCheckActivePaymentNoteALEPay,
    } = props;

    const { t } = useTranslation();
    return (
        <Modal
            id="modal-ale-pay"
            title={!paymentGatewayConfigured ? t('PAYMENTS.SET_UP_PAYMENT_METHOD') : t('PAYMENTS.DETAIL_PAYMENT_METHOD')}
            onCancel={onCancel}
            hasFooter={true}
            onOk={
                !paymentGatewayConfigured
                    ? () => connect(appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE)
                    : () => upDateDataCofigPayment(appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE, paymentGatewayConfigured)
            }
            visible={true}
            width={1000}
            logo={logo}
            bodyStyles={{ minHeight: '40vh' }}
        >
            <div className="content">
                {paymentGatewayConfigured ? (
                    <React.Fragment>
                        <React.Fragment>
                            <div className="partner-account">
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">{t('PAYMENTS.LB_METHOD_NAME')}</label>
                                    <Input 
                                        ref={(ref) => alePayRef.add(ref)}
                                        value={dataUpdateAlePay.method_name}
                                        validationName={t('PAYMENTS.LB_METHOD_NAME')}
                                        name='method_name'
                                        onChange={(event) => onchangeDataUpdateAlePay(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className="ladiui form-group">
                                <label className="ladiui-label">{t("PAYMENTS.LB_METHOD_DESCRIPTION")}</label>
                                <Input 
                                  ref={(ref) => alePayRef.add(ref)}
                                  value={dataUpdateAlePay.description}
                                  validationName={t('PAYMENTS.LB_METHOD_DESCRIPTION')}
                                  name='description'
                                  onChange={(e) => onchangeDataUpdateAlePay(e)}
                                  validations={{ isRequired: true }}
                                 />
                              </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label">{t('PAYMENTS.ALE_PAY.TOKEN_KEY')}</label>
                                    <Input value={paymentGatewayConfigured.config.token_key} disabled={true} />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label">{t('PAYMENTS.ALE_PAY.CHECKSUM_KEY')}</label>
                                    <Input value={paymentGatewayConfigured.config.checksum_key} disabled={true} />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label">{t('PAYMENTS.ALE_PAY.ENCRYPT_KEY')}</label>
                                    <Input value={paymentGatewayConfigured.config.encrypt_key} disabled={true} />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label payment-method-title">{t('PAYMENTS.LB_SELECT_PAYMENT')}</label>
                                    <div className="ladiui-checkout-payment-body">
                                        {map(appConfig.PAYMENT_METHODS.ALE_PAY.TYPE, (paymentMethod) => {
                                            return (
                                                <div key={paymentMethod.CODE} className="ladiui-checkout-payment-item">
                                                    <div className="ladiui-checkout-payment-item-header ladiui-checkout-payment-header-show-2">
                                                        <span>
                                                            <label className="flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                                                                    value={paymentMethod.CODE}
                                                                    onClick={(e) => handleUpdateActivePaymentALEPay(e)}
                                                                    defaultChecked={includes(
                                                                        paymentGatewayConfigured.config.active_payment_methods,
                                                                        paymentMethod.CODE
                                                                    )}
                                                                />
                                                                <span>{paymentMethod.NAME}</span>
                                                            </label>
                                                        </span>
                                                        {dataUpdateAlePay.active_payment_methods.includes(paymentMethod.CODE) && (
                                                            <input
                                                                type="text"
                                                                className="ladiui form-control mt-8 payment-method-note ml-24"
                                                                name={paymentMethod.CODE}
                                                                placeholder="Ghi chú"
                                                                defaultValue={
                                                                    paymentGatewayConfigured.config.active_payment_methods_note
                                                                        ? paymentGatewayConfigured.config.active_payment_methods_note[
                                                                              paymentMethod.CODE
                                                                          ]
                                                                        : ''
                                                                }
                                                                onChange={(event) => handleUpdateActivePaymentNoteALEPay(event)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div id="data-tooltip-guide" className="ladiui form-group">
                                    <label className="ladiui-label">
                                        <span className="tooltip-paymet-guide">{t('PAYMENTS.LB_PAYMENT_GUIDE')}</span>
                                        <span
                                            className="tooltip-icon"
                                            data-tooltip={t('PAYMENTS.LB_TT_PAYMENT_GUIDE')}
                                            data-tooltip-position="right"
                                        >
                                            {' '}
                                            <i className="ladi-icon icon-c-question" />
                                        </span>
                                    </label>
                                    <textarea
                                        name="payment_guide"
                                        className="ladiui textarea"
                                        defaultValue={paymentGatewayConfigured.config.payment_guide}
                                        onChange={(e) => onchangeDataUpdateAlePay(e)}
                                    />
                                </div>
                                {baseHelper.isDevelopEnv() && (
                                    <div className="ladiui form-group flex">
                                        <input
                                            type="checkbox"
                                            className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                                            checked={paymentGatewayConfigured.mode === appConfig.MODE.TEST}
                                            disabled={true}
                                        />
                                        <label htmlFor="ale_pay_mode"> {t('MODE.TEST')}</label>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div>
                            <div className="partner-account">
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">{t('PAYMENTS.LB_METHOD_NAME')}</label>
                                    <Input
                                        ref={(ref) => alePayRef.add(ref)}
                                        value={alePayAccount.method_name}
                                        validationName={t('PAYMENTS.LB_METHOD_NAME')}
                                        name="method_name"
                                        onChange={(event) => onChangeAlePayInput(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label">{t('PAYMENTS.ALE_PAY.TOKEN_KEY')}</label>
                                    <Input
                                        ref={(ref) => alePayRef.add(ref)}
                                        name="token_key"
                                        value={alePayAccount.token_key}
                                        validationName={t('PAYMENTS.ALE_PAY.TOKEN_KEY')}
                                        onChange={(event) => onChangeAlePayInput(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label">{t('PAYMENTS.ALE_PAY.CHECKSUM_KEY')}</label>
                                    <Input
                                        ref={(ref) => alePayRef.add(ref)}
                                        name="checksum_key"
                                        value={alePayAccount.checksum_key}
                                        validationName={t('PAYMENTS.ALE_PAY.CHECKSUM_KEY')}
                                        onChange={(event) => onChangeAlePayInput(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label">{t('PAYMENTS.ALE_PAY.ENCRYPT_KEY')}</label>
                                    <Input
                                        ref={(ref) => alePayRef.add(ref)}
                                        name="encrypt_key"
                                        value={alePayAccount.encrypt_key}
                                        validationName={t('PAYMENTS.ALE_PAY.ENCRYPT_KEY')}
                                        onChange={(event) => onChangeAlePayInput(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label payment-method-title">{t('PAYMENTS.LB_SELECT_PAYMENT')}</label>
                                    <div className="ladiui-checkout-payment-body">
                                        {map(appConfig.PAYMENT_METHODS.ALE_PAY.TYPE, (paymentMethod) => {
                                            return (
                                                <div key={paymentMethod.CODE} className="ladiui-checkout-payment-item">
                                                    <div className="ladiui-checkout-payment-item-header ladiui-checkout-payment-header-show-2">
                                                        <span>
                                                            <label className="flex">
                                                                <input
                                                                    type="checkbox"
                                                                    value={paymentMethod.CODE}
                                                                    onClick={(e) => handleCheckActivePaymentALEPay(e)}
                                                                    className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                                                                    defaultChecked={includes(
                                                                        alePayAccount.active_payment_methods,
                                                                        paymentMethod.CODE
                                                                    )}
                                                                />
                                                                <span>{paymentMethod.NAME}</span>
                                                            </label>
                                                        </span>
                                                        {alePayAccount.active_payment_methods.includes(paymentMethod.CODE) && (
                                                            <input
                                                                type="text"
                                                                className="ladiui form-control mt-8 payment-method-note ml-24"
                                                                name={paymentMethod.CODE}
                                                                placeholder="Ghi chú"
                                                                defaultValue=""
                                                                onChange={(event) => handleCheckActivePaymentNoteALEPay(event)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div id="data-tooltip-guide" className="ladiui form-group">
                                    <label className="ladiui-label">
                                        <span className="tooltip-paymet-guide">{t('PAYMENTS.LB_PAYMENT_GUIDE')}</span>
                                        <span
                                            className="tooltip-icon"
                                            data-tooltip={t('PAYMENTS.LB_TT_PAYMENT_GUIDE')}
                                            data-tooltip-position="right"
                                        >
                                            {' '}
                                            <i className="ladi-icon icon-c-question" />
                                        </span>
                                    </label>
                                    <textarea
                                        name="payment_guide"
                                        className="ladiui textarea"
                                        value={alePayAccount.payment_guide}
                                        onChange={(event) => onChangeAlePayInput(event)}
                                    />
                                </div>
                                {baseHelper.isDevelopEnv() && (
                                    <div className="ladiui form-group">
                                        <input
                                            id="ale_pay_mode"
                                            type="checkbox"
                                            className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                                            onChange={() => setAlePayMode(!alePayMode)}
                                            checked={alePayMode == appConfig.MODE.TEST}
                                        />
                                        <label htmlFor="ale_pay_mode"> {t('MODE.TEST')}</label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </Modal>
    );
}
