import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';

import baseHelper from '../../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import appConfig from '../../../../config/app';
import { map, includes } from 'lodash';

export default function ModalFinan(props) {
    const {
        paymentGatewayConfigured,
        onCancel,
        upDateDataCofigPayment,
        connect,
        isSubmitLoading,
        dataUpdateGateway,
        onchangeDataUpdateGateway,
        finanRef,
        paymentGatewayAccount,
        onChangeGatewayInput,
        gatewayMode,
        setFinanPayMode,
        logo,
    } = props;

    const { t } = useTranslation();

    return (
        <Modal
            id='modal-finan-pay'
            title={!paymentGatewayConfigured ? t('PAYMENTS.SET_UP_PAYMENT_METHOD') : t('PAYMENTS.DETAIL_PAYMENT_METHOD')}
            onCancel={onCancel}
            hasFooter={true}
            onOk={
                !paymentGatewayConfigured
                    ? () => connect(appConfig.PAYMENT_PARTNERS.FINAN.CODE)
                    : () => upDateDataCofigPayment(appConfig.PAYMENT_PARTNERS.FINAN.CODE, paymentGatewayConfigured)
            }
            visible={true}
            width={1000}
            logo={logo}
            bodyStyles={{ minHeight: '40vh' }}
            isLoading={isSubmitLoading}
        >
            <div className='content'>
                {paymentGatewayConfigured ? (
                    <React.Fragment>
                        <React.Fragment>
                            <div className='partner-account'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.LB_METHOD_NAME')}</label>

                                    <Input
                                        ref={(ref) => finanRef.add(ref)}
                                        value={dataUpdateGateway.method_name}
                                        validationName={t('PAYMENTS.LB_METHOD_NAME')}
                                        name='method_name'
                                        onChange={(event) => onchangeDataUpdateGateway(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">{t("PAYMENTS.LB_METHOD_DESCRIPTION")}</label>
                                    <Input 
                                      ref={(ref) => finanRef.add(ref)}
                                      value={dataUpdateGateway.description}
                                      validationName={t('PAYMENTS.LB_METHOD_DESCRIPTION')}
                                      name='description'
                                      onChange={(e) => onchangeDataUpdateGateway(e)}
                                      validations={{ isRequired: true }}
                                     />
                                  </div>

                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.FINAN.SECRET_KEY')}</label>
                                    <Input value={paymentGatewayConfigured.config.secret_key} disabled={true} />
                                </div>

                                <div id='data-tooltip-guide' className='ladiui form-group'>
                                    <label className='ladiui-label'>
                                        <span className='tooltip-paymet-guide'>{t('PAYMENTS.LB_PAYMENT_GUIDE')}</span>
                                        <span
                                            className='tooltip-icon'
                                            data-tooltip={t('PAYMENTS.LB_TT_PAYMENT_GUIDE')}
                                            data-tooltip-position='right'
                                        >
                                            {' '}
                                            <i className='ladi-icon icon-c-question' />
                                        </span>
                                    </label>
                                    <textarea
                                        name='payment_guide'
                                        className='ladiui textarea'
                                        defaultValue={dataUpdateGateway.payment_guide}
                                        onChange={(e) => onchangeDataUpdateGateway(e)}
                                    />
                                </div>
                                {baseHelper.isDevelopEnv() && (
                                    <div className='ladiui form-group flex'>
                                        <input
                                            type='checkbox'
                                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                            checked={paymentGatewayConfigured.mode === appConfig.MODE.TEST}
                                            disabled={true}
                                        />
                                        <label htmlFor='paypal_mode'> {t('MODE.TEST')}</label>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div>
                            <div className='partner-account'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.LB_METHOD_NAME')}</label>
                                    <Input
                                        ref={(ref) => finanRef.add(ref)}
                                        value={paymentGatewayAccount.method_name}
                                        validationName={t('PAYMENTS.LB_METHOD_NAME')}
                                        name='method_name'
                                        onChange={(event) => onChangeGatewayInput(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className='ladiui form-group'>
                                    <div className='label-finan-create'>
                                        <label className='ladiui-label p-0'>{t('PAYMENTS.FINAN.SECRET_KEY')}</label>
                                        <a href='https://ladipage.finan.vn' target='_blank'>
                                            Đăng ký tài khoản Finan
                                        </a>
                                    </div>
                                    <Input
                                        ref={(ref) => finanRef.add(ref)}
                                        value={paymentGatewayAccount.secret_key}
                                        validationName={t('PAYMENTS.FINAN.SECRET_KEY')}
                                        name='secret_key'
                                        onChange={(event) => onChangeGatewayInput(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                <div id='data-tooltip-guide' className='ladiui form-group'>
                                    <label className='ladiui-label'>
                                        <span className='tooltip-paymet-guide'>{t('PAYMENTS.LB_PAYMENT_GUIDE')}</span>
                                        <span
                                            className='tooltip-icon'
                                            data-tooltip={t('PAYMENTS.LB_TT_PAYMENT_GUIDE')}
                                            data-tooltip-position='right'
                                        >
                                            {' '}
                                            <i className='ladi-icon icon-c-question' />
                                        </span>
                                    </label>
                                    <textarea
                                        name='payment_guide'
                                        className='ladiui textarea'
                                        value={paymentGatewayAccount.payment_guide}
                                        onChange={(event) => onChangeGatewayInput(event)}
                                    />
                                </div>
                                {baseHelper.isDevelopEnv() && (
                                    <div className='ladiui form-group flex'>
                                        <input
                                            id='paypal_mode'
                                            type='checkbox'
                                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                            onChange={() => setFinanPayMode(!gatewayMode)}
                                            checked={gatewayMode == appConfig.MODE.TEST}
                                        />
                                        <label htmlFor='paypal_mode'> {t('MODE.TEST')}</label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </Modal>
    );
}
