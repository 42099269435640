/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { map, remove } from 'lodash';
import Modal from '../../../../components/Modal';

import baseHelper from '../../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import { useState, useEffect } from 'react';
import appConfig from '../../../../config/app';
import { includes, compact, find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import settingActions from '../../../../redux/futures/setting/actions';
import * as settingTypes from '../../../../redux/futures/setting/types';
import produce from 'immer';
import * as paymentTypes from '../../../../redux/futures/payment/types';

ModalZaloPay.propTypes = {
    onCancel: PropTypes.func,
    paymentGatewayConfigured: PropTypes.any,
};

export default function ModalZaloPay(props) {
    const {
        paymentGatewayConfigured,
        onCancel,
        isShowZaloPaySetting,
        setZaloPayAccount,
        zaloPayRef,
        zaloPayAccount,
        onChangeZaloPayInput,
        handleCheckActivePaymentZaloPay,
        setZaloPayMode,
        zaloPayMode,
        setIsShowZaloPaySetting,
        isSubmitLoading,
        handleUpdateActivePaymentZaloPay,
        onchangeDataUpdateZaloPay,
        upDateDataCofigPayment,
        connect,
        logo,
        dataUpdateZaloPay,
        handleUpdateActivePaymentNoteZaloPay,
        handleCheckActivePaymentNoteZaloPay,
    } = props;

    const { t } = useTranslation();

    return (
        <Modal
            id="modal-zalo-pay"
            title={!paymentGatewayConfigured ? t('PAYMENTS.SET_UP_PAYMENT_METHOD') : t('PAYMENTS.DETAIL_PAYMENT_METHOD')}
            onCancel={onCancel}
            hasFooter={true}
            onOk={
                !paymentGatewayConfigured
                    ? () => connect(appConfig.PAYMENT_PARTNERS.ZALO_PAY.CODE)
                    : () => upDateDataCofigPayment(appConfig.PAYMENT_PARTNERS.ZALO_PAY.CODE, paymentGatewayConfigured)
            }
            visible={true}
            width={1000}
            logo={logo}
            bodyStyles={{ minHeight: '40vh' }}
        >
            <div className="content">
                {paymentGatewayConfigured ? (
                    <React.Fragment>
                        <React.Fragment>
                            <div className="partner-account">
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">{t('PAYMENTS.LB_METHOD_NAME')}</label>
                                    <Input 
                                         ref={(ref) => zaloPayRef.add(ref)}
                                         value={dataUpdateZaloPay.method_name}
                                         validationName={t('PAYMENTS.LB_METHOD_NAME')}
                                         name='method_name'
                                         onChange={(e) => onchangeDataUpdateZaloPay(e)}
                                         validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className="ladiui form-group">
                                        <label className="ladiui-label">{t("PAYMENTS.LB_METHOD_DESCRIPTION")}</label>
                                        <Input 
                                          ref={(ref) => zaloPayRef.add(ref)}
                                          value={dataUpdateZaloPay.description}
                                          validationName={t('PAYMENTS.LB_METHOD_DESCRIPTION')}
                                          name='description'
                                          onChange={(e) => onchangeDataUpdateZaloPay(e)}
                                          validations={{ isRequired: true }}
                                         />
                                      </div>
                                <div className="ladiui form-group">
                                    <label className="ladiui-label">{t('PAYMENTS.ZALO_PAY.APP_ID')}</label>
                                    <Input value={paymentGatewayConfigured.config.app_id} disabled={true} />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label">{t('PAYMENTS.ZALO_PAY.APP_USER')}</label>
                                    <Input value={paymentGatewayConfigured.config.app_user} disabled={true} />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label">{t('PAYMENTS.ZALO_PAY.KEY_1')}</label>
                                    <Input value={paymentGatewayConfigured.config.key_1} disabled={true} />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label">{t('PAYMENTS.ZALO_PAY.KEY_2')}</label>
                                    <Input value={paymentGatewayConfigured.config.key_2} disabled={true} />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label payment-method-title">{t('PAYMENTS.LB_SELECT_PAYMENT')}</label>
                                    <div className="ladiui-checkout-payment-body">
                                        {map(appConfig.PAYMENT_METHODS.ZALO_PAY.TYPE, (paymentMethod) => {
                                            return (
                                                <div key={paymentMethod.CODE} className="ladiui-checkout-payment-item">
                                                    <div className="ladiui-checkout-payment-item-header ladiui-checkout-payment-header-show-2">
                                                        <span>
                                                            <label className="flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                                                                    value={paymentMethod.CODE}
                                                                    onClick={(e) => handleUpdateActivePaymentZaloPay(e)}
                                                                    defaultChecked={includes(
                                                                        paymentGatewayConfigured.config.active_payment_methods,
                                                                        paymentMethod.CODE
                                                                    )}
                                                                />
                                                                <span>{paymentMethod.NAME}</span>
                                                            </label>
                                                        </span>
                                                        {dataUpdateZaloPay.active_payment_methods.includes(paymentMethod.CODE) && (
                                                            <input
                                                                type="text"
                                                                className="ladiui form-control mt-8 payment-method-note ml-24"
                                                                name={paymentMethod.CODE}
                                                                placeholder="Ghi chú"
                                                                defaultValue={
                                                                    paymentGatewayConfigured.config.active_payment_methods_note
                                                                        ? paymentGatewayConfigured.config.active_payment_methods_note[
                                                                              paymentMethod.CODE
                                                                          ]
                                                                        : ''
                                                                }
                                                                onChange={(event) => handleUpdateActivePaymentNoteZaloPay(event)}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div id="data-tooltip-guide" className="ladiui form-group">
                                    <label className="ladiui-label">
                                        <span className="tooltip-paymet-guide">{t('PAYMENTS.LB_PAYMENT_GUIDE')}</span>
                                        <span
                                            className="tooltip-icon"
                                            data-tooltip={t('PAYMENTS.LB_TT_PAYMENT_GUIDE')}
                                            data-tooltip-position="right"
                                        >
                                            {' '}
                                            <i className="ladi-icon icon-c-question" />
                                        </span>
                                    </label>
                                    <textarea
                                        name="payment_guide"
                                        className="ladiui textarea"
                                        defaultValue={paymentGatewayConfigured.config.payment_guide}
                                        onChange={(event) => onchangeDataUpdateZaloPay(event)}
                                    />
                                </div>

                                {baseHelper.isDevelopEnv() && (
                                    <div className="ladiui form-group flex">
                                        <input
                                            type="checkbox"
                                            className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                                            checked={paymentGatewayConfigured.mode === appConfig.MODE.TEST}
                                            disabled={true}
                                        />
                                        <label htmlFor="zalopay_mode"> {t('MODE.TEST')}</label>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {
                            <div>
                                <div className="partner-account">
                                    <div className="ladiui form-group">
                                        <label className="ladiui-label">{t('PAYMENTS.LB_METHOD_NAME')}</label>
                                        <Input
                                            ref={(ref) => zaloPayRef.add(ref)}
                                            value={zaloPayAccount.method_name}
                                            validationName={t('PAYMENTS.LB_METHOD_NAME')}
                                            name="method_name"
                                            onChange={(event) => onChangeZaloPayInput(event)}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>

                                    <div className="ladiui form-group">
                                        <label className="ladiui-label">{t('PAYMENTS.ZALO_PAY.APP_ID')}</label>
                                        <Input
                                            ref={(ref) => zaloPayRef.add(ref)}
                                            name="app_id"
                                            value={zaloPayAccount.app_id}
                                            validationName={t('PAYMENTS.ZALO_PAY.APP_ID')}
                                            onChange={(event) => onChangeZaloPayInput(event)}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>

                                    <div className="ladiui form-group">
                                        <label className="ladiui-label">{t('PAYMENTS.ZALO_PAY.APP_USER')}</label>
                                        <Input
                                            ref={(ref) => zaloPayRef.add(ref)}
                                            name="app_user"
                                            value={zaloPayAccount.app_user}
                                            validationName={t('PAYMENTS.ZALO_PAY.APP_USER')}
                                            onChange={(event) => onChangeZaloPayInput(event)}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>

                                    <div className="ladiui form-group">
                                        <label className="ladiui-label">{t('PAYMENTS.ZALO_PAY.KEY_1')}</label>
                                        <Input
                                            ref={(ref) => zaloPayRef.add(ref)}
                                            name="key_1"
                                            value={zaloPayAccount.key_1}
                                            validationName={t('PAYMENTS.ZALO_PAY.KEY_1')}
                                            onChange={(event) => onChangeZaloPayInput(event)}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>

                                    <div className="ladiui form-group">
                                        <label className="ladiui-label">{t('PAYMENTS.ZALO_PAY.KEY_2')}</label>
                                        <Input
                                            ref={(ref) => zaloPayRef.add(ref)}
                                            name="key_2"
                                            value={zaloPayAccount.key_2}
                                            validationName={t('PAYMENTS.ZALO_PAY.KEY_2')}
                                            onChange={(event) => onChangeZaloPayInput(event)}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>

                                    <div className="ladiui form-group">
                                        <label className="ladiui-label">{t('PAYMENTS.ZALO_PAY.RSA_PUBLIC_KEY')}</label>
                                        <Input
                                            ref={(ref) => zaloPayRef.add(ref)}
                                            name="rsa_public_key"
                                            value={zaloPayAccount.rsa_public_key}
                                            validationName={t('PAYMENTS.ZALO_PAY.RSA_PUBLIC_KEY')}
                                            onChange={(event) => onChangeZaloPayInput(event)}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>

                                    <div className="ladiui form-group">
                                        <label className="ladiui-label payment-method-title">{t('PAYMENTS.LB_SELECT_PAYMENT')}</label>
                                        <div className="ladiui-checkout-payment-body">
                                            {map(appConfig.PAYMENT_METHODS.ZALO_PAY.TYPE, (paymentMethod) => {
                                                return (
                                                    <div key={paymentMethod.CODE} className="ladiui-checkout-payment-item">
                                                        <div className="ladiui-checkout-payment-item-header ladiui-checkout-payment-header-show-2">
                                                            <span>
                                                                <label className="flex">
                                                                    <input
                                                                        type="checkbox"
                                                                        value={paymentMethod.CODE}
                                                                        onClick={(e) => handleCheckActivePaymentZaloPay(e)}
                                                                        className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                                                                        defaultChecked={includes(
                                                                            zaloPayAccount.active_payment_methods,
                                                                            paymentMethod.CODE
                                                                        )}
                                                                    />
                                                                    <span>{paymentMethod.NAME}</span>
                                                                </label>
                                                            </span>
                                                            {zaloPayAccount.active_payment_methods.includes(paymentMethod.CODE) && (
                                                                <input
                                                                    type="text"
                                                                    className="ladiui form-control mt-8 payment-method-note ml-24"
                                                                    name={paymentMethod.CODE}
                                                                    placeholder="Ghi chú"
                                                                    defaultValue=""
                                                                    onChange={(event) => handleCheckActivePaymentNoteZaloPay(event)}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>

                                    <div id="data-tooltip-guide" className="ladiui form-group">
                                        <label className="ladiui-label">
                                            <span className="tooltip-paymet-guide">{t('PAYMENTS.LB_PAYMENT_GUIDE')}</span>
                                            <span
                                                className="tooltip-icon"
                                                data-tooltip={t('PAYMENTS.LB_TT_PAYMENT_GUIDE')}
                                                data-tooltip-position="right"
                                            >
                                                {' '}
                                                <i className="ladi-icon icon-c-question" />
                                            </span>
                                        </label>
                                        <textarea
                                            name="payment_guide"
                                            className="ladiui textarea"
                                            value={zaloPayAccount.payment_guide}
                                            onChange={(event) => onChangeZaloPayInput(event)}
                                        />
                                    </div>
                                    {baseHelper.isDevelopEnv() && (
                                        <div className="ladiui form-group flex">
                                            <input
                                                id="zalopay_mode"
                                                type="checkbox"
                                                className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                                                onChange={() => setZaloPayMode(!zaloPayMode)}
                                                checked={zaloPayMode == appConfig.MODE.TEST}
                                            />
                                            <label htmlFor="zalopay_mode"> {t('MODE.TEST')}</label>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </React.Fragment>
                )}
            </div>
        </Modal>
    );
}
